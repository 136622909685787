//@import "global/fonts";
//@import "global/colours";
//@import "global/foundation";
@import "framework7/css/bundle";
@import "framework7-icons/css/framework7-icons.css";
//@import "global/fontawesome-settings";

$color1: rgb(83, 112, 120);
$color2: #79A5B0;
$color3:rgb(148, 201, 214);
$color4: rgb(165, 225, 240);
$color5: #AFEEFD;

:root {
    --f7-font-size: 15px;
    --f7-button-font-size: 15px;
    --f7-inline-label-font-size: 15px;
    --f7-table-head-cell-height: 28px;
    --f7-table-head-bg-color: #E7E6E5;
    --f7-table-body-cell-height: 34px;
    --f7-table-cell-padding-horizontal: 10px;
    --f7-table-edge-cell-padding-horizontal: 5px;
    --f7-list-item-padding-horizontal: 5px;
    --f7-block-padding-horizontal: 0;
    --f7-list-in-list-padding-left: 5px;
    --f7-list-in-list-padding-right: 5px;
    --f7-input-padding-left: 0;
    --f7-input-padding-right: 0;
    --f7-list-item-padding-vertical: 0;
    --f7-label-height: 2;
    --f7-panel-right-width: 450px;
    --f7-list-item-min-height: 38px;
    --f7-block-title-text-color:#1b1b1f;
}

.myLink {
    text-decoration: underline;
     cursor:pointer;
}

.card {
    padding: 0 0.5rem 1rem;
    margin:0;
    border: solid 1px $color1;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #FFFFFF;
    .card-header {
        padding-right: 0;
        padding-left: 0;
        font-size: 1.3rem;
        font-weight: bold;
        padding-bottom: 0
    }


    .bordered {
        height: 99px;
        width: 99px;
        border: solid 1px #FFFFFF;
        border-radius: 5px;
        padding: 0.2rem 0.5rem;
        margin: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
}

.media-item {
    cursor: pointer;
    .item-title {
        font-weight: bold !important;
    }
}

.table-holder {
    //max-height: 378px;
    //overflow-y: auto;
    table {
        width: 100%;
    }
    tr {
        cursor: pointer;
    }
    td {
        background-color: #FFFFFF;
    }
}

.chip {
    cursor: pointer;
}

h2 {
    margin-bottom: 0;
    
}
p {
    margin: 0.5rem 0;
}

.dialog-text {
    font-size: 1rem;
}

.panel, .page-content {
    padding: 0 0.8rem;
}
.block {
    width: 100% !important;
}

h1 {
    margin: 1rem;
    font-family: Roboto, system-ui, Noto;
    font-size: 1.6rem;
    font-weight: normal;
}
h2 {
    font-size:1.3rem;
}
h3 {
    padding:0.5rem 0 0.5rem 0;
    font-weight: normal;
}
h4 {
    margin: 0;
    padding:0.5rem 0 0.5rem 0;
}
h5 {
    margin: 0;
    padding:0.5rem 0 0 0;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
}
.menu {
    margin: 0 1.5rem;
    padding: 0.2rem 0;
    display: inline-block;
    &.primary {
        float: right;
        margin-right:0;
        .menu-inner {
            justify-content: flex-end;
        }
    }

}
.main-menu {
    margin-bottom: 0;
    background-color: #E7E6E5;
    padding: 0.3rem 0;
}
.actions-button {
    &:hover {
        background-color: $color2;
        cursor: pointer;
    }
    border-bottom: solid 1px #ffffff;
}

a {
    color:black;
    font-weight: normal;
    //text-decoration: underline;
    &.menu-item, &.link {
        text-decoration: none;
    }
}

.panel {
    min-width: 500px;
    max-width: 90vw;
  }

.block {
    margin-left: 0;
    padding-bottom: 1rem;
}

img {
    margin:auto !important;
}

html,body {
    height:100%;

}
.wrapper         {width:100%; max-width: 600px; height:100%; margin:0; background:#CCC}
.h_iframe        {position:relative; padding-top: 75%;}
.h_iframe iframe {position:absolute;top:0;left:0;width:100%; height:100%;
    img {
        width: 100%;
    }
}
.view-main {
    .page-content {
        background-color: #eee;
        
        .tab {
            background-color: white;
        }
        .block-title {
            margin-top: 0;
        }

    }
}



.search-list li:hover, .search-list li.selected {
	background-color:$color1 ;
	color: white;
}

.tabbar .tab-link-active, .tabbar-labels .tab-link-active {
    font-weight: bold;
}

.scrolling-area {
    height: calc(100vh - (64px + 32px  + 48px + 16px + 25px ));
    overflow-y: scroll;
    margin-bottom: 0;
}

.category-scrolling-area {
    height: calc(100vh - (64px + 67px + 30px));
    overflow-y: scroll;
    margin-bottom: 0;
}

.no-bottom-margin {
    margin-bottom: 0;
}
.no-top-margin {
    margin-top: 0;
}

.searchbar~* {
    margin-top:0;
}

.menu-item-content {
    color: white;
    //font-weight: bold;
}

.artwork-chooser {
    //height:100vh;
    .h_iframe        {position:relative; padding-top: 51%;}
    overflow-y: scroll;
    overflow-x: hidden; 
    padding-bottom: 5rem;
    .worktypes {
        .list ul:before {
            height: 0 !important;
        }
        .list ul:after {
            height: 0;
        }
    }
    ul {
        padding:0;
    }
}

#garment-configurator {
    .artwork-chooser {
        padding: 0;
        //height: unset;
        overflow-y: hidden;
        overflow-x: hidden;
        margin-bottom: 0.2rem;
    }
    .list textarea {
        height: 3rem;
    }
}

#catalogue {
    .list li:hover, .list li.selected {
        background-color: $color1 ;
    }
    .block-title {
        background-color: $color1;
        margin: 0;
        padding: 1rem;
        color: white;
    }
}

input[type=number] {
    text-align: right !important;
    border: solid 1px #eee;
    background-color: #FEF9E7;
    width: 80px;
}

input[type=number]:read-only {
    text-align: right !important;
    border: none;
    background-color: none;
}
p.note {
    margin:0; padding: 0.2rem 0; font-size: 0.8rem;
}
.f7-icons {
    font-size: 21px;
    cursor: pointer;
}

.page-content .searchbar:not(.searchbar-inline) {
    box-shadow: none;
    border-bottom: solid 1px #eee;
    margin: 0;
}

.data-table {
    margin: 0;
}

.menu-dropdown-item, .menu-dropdown-link{
    cursor: pointer;
}

.schedule {
    
    h3 {
        text-align: left;
        font-weight: normal;
    }
    padding: 0.5rem;
    border: none;
    //border-radius: 0 10px 10px 0;
    background-color: white;
    margin: 0;
   
    a {
        font-weight: normal;
        &:hover {
            text-decoration: underline;
        }
    }
    .day {
        display: block;

        &.today {
            font-weight: normal;
            padding: 0.3rem;
        }
    }    
    &.Run {
        //border: solid 1px #ADD8E6;
        h3 {
            color: #79A5B0;
        }
        .day {
            border-top: solid 1px #79A5B0;
            background-color: $color4; 
            padding: 5px;
            margin-top: 1rem;
            &.today {
                text-transform: uppercase;
                background-color: #79A5B0;
                margin-top: 0;
            }            
        }
        .alert {
            background-color: #79A5B0;
            padding: 0.5rem;
            border-radius: 0 0 10px 0;
        }
    }
    &.Despatch {
        //border: solid 1px #6082B6;
        h3 {
            color: $color3;
        }
        .day {
            border-top: solid 1px $color3;
            background-color: $color4;
            padding: 5px;
            margin-top: 1rem;
            &.today {
                background-color: #79A5B0;
            }             
        }
        .alert {
            background-color: $color3;
            padding: 0.5rem;
            border-radius: 0 0 10px 0;
        }
    }    
}
/*
a.icon-button {
    //color: white;
    padding: 4px;
    display: inline-block;
    margin: 0 2px;
    border-radius: 2px;


}*/
#order-page {
    input[type=text] {
        background-color: #ffffff;
        padding: 2px;
        border: none;
        width:100%;
    }

    textarea {
        background-color: #ffffff;
        padding: 2px;
        width:100%;
    }
}

.highlight {
    background-color: $color3;
    margin: 4px;
	padding: 3px 5px;
	font-weight: normal;
}

.toolbar {
    margin-top:3.5rem;
}

div.right {
    text-align: right;
    p {
        text-align: right;
    }
}
.item-input.item-content {
    margin: 0 !important;
}
.list {
    margin-top: 0;
    margin-bottom: 0;
}

#account {
    .tab {
        &.page-content {
            background-color: unset !important;
        }
    }
}